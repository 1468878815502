import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogAlertComponent } from "./dialog-alert.component";

type typeAlert = "danger" | "info" | "warning";
export interface OptionsAlert {
	title?: string;
	message?: string;
	nameResource?: null | string;
	callback?: Function; // Funcion que devuelve el servicio a consumir el la alerta
	messageSuccess?: string;
	typeAlert?: typeAlert;
	icon?: string;
	durationResponse?: number; // Tiempo de vida de SnackBar, luego del tiempo se oculta
	errorIntoAlert?: boolean;
	withAlertSucess?: boolean; // muestra alerta de exito
	withAlertError?: boolean; // muestra alerta de error,
	closeDialogAfterCompleteCallback?: boolean; // invoca el metodo de cerrar el dialogo en la funcion 'complete' del callback
	returnSuccessResponse?: boolean;
	returnErrorResponse?: boolean;
}

const initialValues: OptionsAlert = {
	title: "Confirmar",
	message: "Desea eliminar el dato, una vez realizado no se podrá recuperar.",
	messageSuccess: "Recurso eliminado!",
	typeAlert: "danger",
	icon: "warning",
	durationResponse: 4000,
	errorIntoAlert: false,
	withAlertSucess: true,
	withAlertError: true,
	closeDialogAfterCompleteCallback: true,
	returnSuccessResponse: false,
	returnErrorResponse: false,
};

@Injectable({
	providedIn: "root",
})
export class DialogAlertService {
	constructor(private dialog: MatDialog) { }

	confirm(config?: OptionsAlert) {
		const data: OptionsAlert = { ...initialValues, ...config };

		const dialog = this.dialog.open(DialogAlertComponent, {
			width: "460px",
			disableClose: true,
			data: data,
		});

		return dialog.beforeClosed();
	}
}
